var gasForm = document.getElementById('new_quierogas_order');
var retrieveAtStorageButton = gasForm.querySelectorAll('label[for="quierogas_order_order_type_retrieve_at_storage"]')[0];
var localShippingButton = gasForm.querySelectorAll('label[for="quierogas_order_order_type_local_shipping"]')[0];
var collapsibleDiv = document.getElementById('local_shipping');
var orderAddress = document.getElementById('quierogas_order_address');
var inputs = collapsibleDiv.getElementsByTagName('input');
var productPrices = document.getElementsByClassName('product-price');
var retrievePrices = document.getElementsByClassName('product-retrieve_price');

var productDiscountPrices = document.getElementsByClassName('product-discount-price');
var productRetrieveDiscountPrices = document.getElementsByClassName('product-retrieve_discount_price');

var retrieveSelected = false;
var localShippingSelected = true;

if (retrieveAtStorageButton && localShippingButton) {
  /* Retiro en tienda */
  retrieveAtStorageButton.addEventListener('click', function() {
    retrieveSelected = true;
    localShippingSelected = false;

    collapsibleDiv.classList.remove('d-block');
    collapsibleDiv.classList.add('d-none');

    document.getElementById('local-col').classList.remove('d-block');
    document.getElementById('local-col').classList.add('d-none');

    document.getElementById('retrieve-col').classList.remove('d-none');
    document.getElementById('retrieve-col').classList.add('d-block');
    
    productPrices.forEach((element) => {
      element.classList.remove('d-block');
      element.classList.add('d-none');
    });

    retrievePrices.forEach((element) => {
      element.classList.remove('d-none');
      element.classList.add('d-block');
    })

    productDiscountPrices.forEach((element) => {
      element.classList.remove('d-block');
      element.classList.add('d-none');
    });

    productRetrieveDiscountPrices.forEach((element) => {
      element.classList.remove('d-none');
      element.classList.add('d-block');
    })

    for(var input of inputs) {
      input.value = ''
    }

    sumWebTotal();
  })

  /* Envio a domicilio */
  localShippingButton.addEventListener('click', function() {
    retrieveSelected = false;
    localShippingSelected = true;

    collapsibleDiv.classList.remove('d-none');
    collapsibleDiv.classList.add('d-block');

    document.getElementById('retrieve-col').classList.remove('d-block');
    document.getElementById('retrieve-col').classList.add('d-none');
    document.getElementById('local-col').classList.remove('d-none');
    document.getElementById('local-col').classList.add('d-block');

    productPrices.forEach((element) => {
      element.classList.remove('d-none');
      element.classList.add('d-block');
    });

    retrievePrices.forEach((element) => {
      element.classList.remove('d-block');
      element.classList.add('d-none');
    })

    productDiscountPrices.forEach((element) => {
      element.classList.remove('d-none');
      element.classList.add('d-block');
    });

    productRetrieveDiscountPrices.forEach((element) => {
      element.classList.remove('d-block');
      element.classList.add('d-none');
    })


    sumWebTotal();
  });
  
}


var fillOrderAddressInput = () => {
  var words = []
  for (var input of inputs) {
    if (input.value != '') {
      words.push(input.value);
    }
  }
  // Tomo el hidden input y cambio el value a la concatenación de todos los inputs con datos
  // de la dirección
  orderAddress.value = words.join(', ');
};


var productsForm = document.getElementById('products-form');
var orderTotal = document.getElementsByClassName('total-value')[0];
var products = productsForm.getElementsByClassName('product-col');
productsForm.addEventListener('change', (event) => {
  sumWebTotal();
})

var setRequestedProducts = () => {
  var requestedProductsInput = document.getElementById('quierogas_order_requested_products');
  var json = {};
  json.products = [];
  for(var product of products) {
    var name = product.getElementsByClassName('product-name')[0].innerHTML;
    if (retrieveSelected == true){
      var price = product.getElementsByClassName('product-retrieve_price')[0];
    } else {
      var price = product.getElementsByClassName('product-price')[0];
    }
    var val = parseInt(price.attributes.getNamedItem('data-price').value);
    var amount = product.getElementsByClassName('product-amount')[0].value;
    json.products.push({
      'name': name,
      'price': val,
      'amount': amount
    })
  }
  requestedProductsInput.value = JSON.stringify(json)
};

var sumWebTotal = () => {
  var totalSum = 0;
  for(var product of products) {
    if (retrieveSelected == true){
      var price = product.getElementsByClassName('product-retrieve_price')[0];
    } else {
      var price = product.getElementsByClassName('product-price')[0];
    }
    var val = parseInt(price.attributes.getNamedItem('data-price').value);
    var amount = product.getElementsByClassName('product-amount')[0].value;
    totalSum = totalSum + (val * amount);
  }
  totalSum = totalSum.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
  orderTotal.innerHTML = 'Total: ' + totalSum;
}

gasForm.addEventListener('submit', (event) => {
    fillOrderAddressInput();
    setRequestedProducts();
  }
);